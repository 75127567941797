<template>
  <div class="pageBox has__nav__menu">
    <h1 class="textHide">閲覧アイテム</h1>
    <p v-if="pageTotal" class="txtMenuBtn" @click="toggleModal(true)"><font-awesome-icon icon="trash-alt" /><span class="hide">閲覧履歴を消す</span></p>
    <div id="itemList">
      <div v-if="isLoading" class="fullLoading">
        <loading :size="2" :icon="'spinner'" />
      </div>
      <div v-else v-for="data in getList" :key="data.page">
        <Itemlist v-bind:lists="data.items" />
        <loading v-if="data.loading" :size="2" :icon="'spinner'">
          <p class="loadTxt" v-if="'parseInt(data.page) + 1 < pageTotal'" :id="'page' + Number(parseInt(data.page) + 1)">{{data.next}}ページ目をロードしています</p>
        </loading>
      </div>
    </div>
    <showAll />
    <modalAlert v-if="modalOn">
      <div class="modal-box__block__inner">
        <p class="modal__title">閲覧履歴を削除しますか？</p>
        <p class="notice">この操作は取り消し・復元ができません。</p>
      </div>
      <div class="modal-box__block__buttons">
        <div class="modal-box__block__buttons__btn cancel" @click="toggleModal(false)">
          キャンセル
        </div>
        <div class="modal-box__block__buttons__btn" @click="removeViewed">
          OK
        </div>
      </div>
    </modalAlert>
  </div>
</template>

<script>
import axios from 'axios'
import Itemlist from "@/components/block/Itemlist.vue";
import loading from "@/components/atom/loading.vue";
import {Mixin} from '@/mixins/common'
import showAll from "@/components/parts/showAll.vue";
import modalAlert from "@/components/parts/modalAlert.vue";


export default {
  data() {
    return {
      dataList: {},
      pageTotal: false,
      pageNumber: 1,
      isLoading: true,
      listLoading: false,
      modalOn: false,
      title: '閲覧履歴',
      description: '閲覧履歴ページ。これまで見たアイテムの一覧です。'
    };
  },
  mixins:[Mixin],
  components: {
    Itemlist,
    loading,
    showAll,
    modalAlert,
  },
  mounted () {
    this.initialize()
    this.setupPage()
  },
  computed: {
    getList: function() {
      return this.dataList;
    },
    getTotal: function() {
      return this.dataList;
    }
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
  methods: {
    toggleModal(valid){
      this.modalOn = valid
    },
    initialize(){
      this.dataList = {}
      this.pageNumber = null
      this.isLoading = true
      this.listLoading = false
      this.pageTotal = false
    },
    async setupPage(){
      await this.getData(this.pageNumber);
    },
    async getData(){
      this.listLoading = true
      let viewed = JSON.parse(localStorage.getItem('viewed'));

      if(viewed !== null){
        let url = this.api + '/items/ids/?ids=' + viewed.join() + '&limit'
        console.log(url);

        axios.get(url)
        .then((res) => {
          this.dataList['0'] = {page: 1, next: null, items: res.data.list}
          this.dataList['0'].loading = false
          this.pageTotal = res.data.item_total
          this.isLoading = false
        })
        .catch(() =>{
          console.log('viewed items not found')
         // this.$router.push({ name: "404"})
        })
      }else{
        this.isLoading = false
      }
    },
    removeViewed(){
      localStorage.removeItem('viewed')
      this.initialize()
      this.setupPage()
      this.toggleModal(false)
    }
  }
}
</script>
